

import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import JsonViewer from '@/modules/common/components/ui-kit/json-viewer.vue';
import Intraday from '@/modules/common/components/intraday.vue';
import type Day from '@/modules/common/types/day.type';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import Currency from '@/modules/common/components/currency.vue';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import ScreenshotLink from '@/modules/rates/components/screenshot-link.vue';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import PriceFilter from '@/modules/common/filters/price.filter';
import HotelRooms from '@/modules/common/interfaces/hotelRooms.interface';
import ClipText from '@/modules/common/filters/clip-text.filter';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS, RoomDictionary } from '@/modules/rates/price-history/rates-price-history.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from '../rates-price-history-common.service';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

export interface TableData {
    id: string;
    name: string;
    price: number;
    priceModificator: string;
    priceType?: string;
    roomName: string;
    myHotel: boolean;
    isCompset: boolean;
    link?: string;
    rank?: number | null;
    borderColor?: string;
    analysisPrice?: number;
    isBasic?: boolean;
    isNetCalc?: boolean;
    losRestriction?: boolean | number | null;
    screenshot?: string;
    intradayValue?: number | null;
    specialIntraday?: string | null;
    specialDateTitle?: string | null;
    analysisData?: {
        price?: number;
        isBasic?: boolean;
        losRestriction?: boolean | number | null;
        room: RatesDocumentItemModel | null,
    }[];
}

@Component({
    components: {
        Currency,
        ScreenshotLink,
        Intraday,
        JsonViewer,
        ProviderCard,
    },
})
export default class RatesPriceHistoryTable extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @Inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RatesPriceHistoryServiceS) private ratesPriceHistoryService!: RatesPriceHistoryService;
    @Inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @Inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;

    @Prop({ type: Number, default: null })
    tableDay!: Day | null;

    @Prop({ type: Object, default: () => ({}) })
    hiddenGraphs!: { [k: string]: boolean };

    @Prop({ type: String })
    private priceShown!: PRICE_SHOWN;

    @Prop({ type: Object, required: true })
    private doc!: RatesDocumentModel;

    private get hotelColors() {
        return this.hotelsService.getHotelsGraphColor(this.compsetId);
    }

    get bookingBasicIcon() {
        return BOOKING_BASIC_ICON;
    }

    get isClusterPage() {
        return (
            false
                || this.$route.name!.includes('cluster')
                || this.$route.name!.includes('chain')
        ) && !this.$route.name!.includes('.hotel');
    }

    get isPriceTypeLowest() {
        return this.isClusterPage
            ? this.clusterRatesService.getSettings(this.currentHotelId!)?.priceType === 'lowest'
            : this.ratesService.settings.priceType === 'lowest';
    }

    get isLoading() {
        return this.ratesPriceHistoryService.storeState.loading.isLoading();
    }

    get isIntradayLoading() {
        return this.ratesService.isIntradayLoading;
    }

    get isCheapestChannel() {
        if (this.tableDay) return false;
        if (!this.doc) return false;

        return this.doc.providerName === 'cheapest';
    }

    get currency() {
        return this.ratesPriceHistoryService.currency;
    }

    get compsetId() {
        return this.isClusterPage
            ? this.$route.params.compsetId
            : null;
    }

    get currentHotelId() {
        return this.isClusterPage
            ? Number(this.$route.params.hotelId)
            : this.userService.currentHotelId;
    }

    get historyDate() {
        const { selectedTrendDate } = this.ratesPriceHistoryService;

        if (!selectedTrendDate) {
            return '---';
        }

        return moment(selectedTrendDate).format('DD.MM');
    }

    get days() {
        const { dayIndex } = this.ratesPriceHistoryCommonService;
        return !dayIndex
            ? this.$tc('lastUpdate')
            : `-${this.$t('numDays', [dayIndex]).toString()}`;
    }

    get currentScanDate() {
        const { lastScanDate } = this.ratesPriceHistoryService;
        const { dayIndex } = this.ratesPriceHistoryCommonService;

        if (!lastScanDate) return null;

        const currentScanDay = new Date(lastScanDate);
        currentScanDay.setDate(currentScanDay.getDate() - dayIndex);

        return currentScanDay;
    }

    get analysisKeys() {
        return this.ratesAnalysisFiltersService.comparisonValues
            .map(k => ClipText(k.name, 10));
    }

    get mainKey() {
        const v = String(this.ratesAnalysisFiltersService.mainCompareTitle!);
        return ClipText(v, 10);
    }

    get day() {
        return +this.$route.params.day! as Day;
    }

    get comparisonValues() {
        return this.ratesAnalysisFiltersService.comparisonValues;
    }

    get isAnalysisMode() {
        const routeName = this.$route.name || '';

        return routeName.includes('.analysis');
    }

    get hasIntraday() {
        if (this.isClusterPage || !!this.tableDay) {
            return false;
        }

        const { competitors } = this.compsetsService;
        const isSomeOfCompetitorsHasIntraday = (competitors || [])
            .some(hotelId => this.ratesService
                .isIntraday(this.day, +hotelId));

        return isSomeOfCompetitorsHasIntraday
            || this.ratesService.isIntraday(this.day);
    }

    get hasScreenshots() {
        if (this.isAnalysisMode || this.ratesService.isAllChannelsMode || !this.tableDay) {
            return false;
        }

        const mainScreenshot = this.ratesService.getScreenshot(this.tableDay, this.currentHotelId!);

        if (mainScreenshot) {
            return true;
        }

        return Object
            .keys(this.ratesService.getCompetitorsRooms(this.tableDay))
            .some(hotelId => !!this.ratesService.getScreenshot(this.tableDay!, +hotelId));
    }

    private convertToTableData(compareRooms: RoomDictionary[], mainRooms: RoomDictionary, dayIndex: number, hotelId: number) {
        if (!this.currentScanDate) {
            return {} as TableData;
        }

        const day = this.currentScanDate.getDate();
        const isMyHotel = hotelId === this.currentHotelId;
        const analysisData = this.isAnalysisMode
            ? compareRooms.map((rooms, docIndex) => this.getAnalysisData(day, rooms[hotelId], hotelId, docIndex))
            : [];

        this.ratesPriceHistoryService.setDataKey('main');

        if (!mainRooms) {
            return {} as TableData;
        }

        const currentHotelRoom = mainRooms[hotelId];

        const room = !dayIndex
            ? this.ratesCommonService.getRoom(this.day, hotelId, this.doc, this.priceShown)
            : null;

        const isRoomSoldOut = this.ratesCommonService
            .isSoldOutDay(hotelId, mainRooms, this.priceShown);

        const isNetCalc = room && this.priceShown === PRICE_SHOWN.NET
            ? this.ratesCommonService.getRoom(this.day, hotelId, this.doc, this.priceShown)?.isNetCalc
            : false;

        const price = this.ratesCommonService
            .switchPrice(currentHotelRoom, this.priceShown);
        const isPriceValid = price && price !== -1;

        const priceType = room && isPriceValid
            ? room.priceType
            : '';

        const hotelColor = this.hotelColors[hotelId] || '#000000';
        const hotelName = this.hotelsService.getHotelName(+hotelId) || hotelId;

        if (!currentHotelRoom) {
            return {
                id: String(hotelId),
                name: hotelName,
                price: -1,
                priceType,
                roomName: '-',
                myHotel: isMyHotel,
                isBasic: false,
                isCompset: false,
                analysisData,

                borderColor: isMyHotel
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : hotelColor,
            } as TableData;
        }

        return {
            id: String(hotelId),
            name: hotelName,
            isBasic: currentHotelRoom.isBasic,
            myHotel: hotelId === this.currentHotelId,
            isCompset: false,
            link: !this.tableDay && this.ratesCommonService.getHotelLink(this.day, hotelId, this.doc),
            analysisData,
            screenshot: this.ratesCommonService.getScreenshot(this.day, hotelId, this.doc),
            priceModificator: isNetCalc ? '*' : '',
            price,
            priceType,
            isNetCalc,

            intradayValue: this.hasIntraday
                ? this.ratesService.intradayByHotelId(this.day, hotelId, this.priceShown)
                : null,
            specialIntraday: currentHotelRoom.specialDateName,
            specialDateTitle: currentHotelRoom.specialDateName
                ? `${this.$tc('titles.specdate')}: ${currentHotelRoom.specialDateName}`
                : undefined,

            roomName: isRoomSoldOut || !isPriceValid
                ? '-'
                : currentHotelRoom.roomName,

            borderColor: isMyHotel
                ? CURRENT_HOTEL_GRAPH_COLOR
                : hotelColor,

            losRestriction: this.ratesPriceHistoryService
                .getLosRestriction(dayIndex, hotelId),

            providers: this.ratesService
                .getRoomProviders(this.day, hotelId, this.priceShown),
        } as TableData;
    }

    get tableData() {
        const { ratesSettings, hotels } = this.ratesPriceHistoryService;
        const { dayIndex } = this.ratesPriceHistoryCommonService;

        this.ratesPriceHistoryService.setDataKey('main');

        if (!hotels || !this.currentScanDate || !ratesSettings) {
            return [] as TableData[];
        }

        const compareRooms = this.ratesAnalysisFiltersService.comparisonValues
            .map((_, docIndex) => this.getAnalysisRooms(dayIndex, docIndex));

        const mainRooms = this.getMainRooms(dayIndex);

        let tablePriceHistoryData = hotels.map(this.convertToTableData.bind(this, compareRooms, mainRooms, dayIndex));

        const roomsWithoutPrice = tablePriceHistoryData
            .reduce((acc, el) => ((el.price && el.price !== -1) ? acc : acc + 1), 0);

        tablePriceHistoryData = tablePriceHistoryData
            .sort((a, b) => +b.price - +a.price)
            .map((el, index) => ({
                ...el,
                rank: (el.price && el.price !== -1)
                    ? tablePriceHistoryData.length - index - roomsWithoutPrice
                    : null,
            }));

        this.ratesPriceHistoryService.setDataKey('main');

        const mainCompsetData = this.getCompsetData(mainRooms, this.priceShown);
        const analysisData = this.isAnalysisMode ? compareRooms
            .map(rooms => {
                const data = this.getCompsetData(rooms, this.priceShown);
                if (!data) {
                    return null;
                }

                return {
                    price: data.price || -1,
                    isBasic: false,
                    losRestriction: false,
                };
            })
            .filter(Boolean) as TableData['analysisData'] : [];

        if (mainCompsetData?.price) {
            tablePriceHistoryData.push({
                id: mainCompsetData.compset!.type,
                name: String(this.$t(`compsetRate.${mainCompsetData.compset!.type}`)),
                isCompset: true,
                price: mainCompsetData.price || -1,
                priceModificator: '',
                isBasic: false,
                myHotel: false,
                roomName: '-',
                rank: 0,
                analysisData,
            });
        }

        return tablePriceHistoryData
            .sort((a, b) => b.price - a.price);
    }

    private getCompsetData(rooms: HotelRooms, priceShown: PRICE_SHOWN) {
        const competitorRooms = {
            ...rooms,
        };

        delete competitorRooms[this.currentHotelId!];

        const compset = this.compsetId
            ? this.clusterCompsetsService.getCompsetById(this.compsetId)
            : this.compsetsService.currentCompset;

        if (!compset) return { price: -1, compset: null };

        const price = this.ratesCommonService.getCompsetPrice(competitorRooms, compset!.type, priceShown);

        return { price, compset };
    }

    PriceFilter = PriceFilter;

    hasAnalysisData(hotel: TableData, docIndex = 0) {
        return hotel.analysisData?.[docIndex]
            && hotel.analysisData?.[docIndex].price
            && hotel.analysisData[docIndex].price !== -1;
    }

    isAnalysisDataSoldOut(hotel: TableData, docIndex = 0) {
        return hotel.analysisData?.[docIndex]
            && hotel.analysisData?.[docIndex].price === 0;
    }

    isMainRoomDifferent(hotel: TableData, docIndex = 0) {
        if (!hotel.analysisData) return false;

        const compareRoomName = hotel.analysisData[docIndex]?.room?.roomName;
        const mainRoomName = hotel.roomName;

        return compareRoomName !== mainRoomName;
    }

    isValidComparePrice(hotel: TableData, docIndex = 0) {
        return hotel.analysisData?.[docIndex]
            && hotel.analysisData?.[docIndex].price
            && hotel.analysisData[docIndex].price !== -1;
    }

    getMainRooms(day: number) {
        this.ratesPriceHistoryService.setDataKey('main');

        return this.ratesPriceHistoryService
            .getSuitableRoomByDay(day);
    }

    getAnalysisRooms(day: number, documentIndex = 0) {
        const { comparisonValues, comparisonKey } = this.ratesAnalysisFiltersService;
        const key = comparisonValues[documentIndex]?.name;

        if (!key) return {};

        const diffDays = comparisonKey === 'diffDays'
            ? comparisonValues[documentIndex].value as number
            : 0;

        return this.ratesPriceHistoryService
            .setDataKey(key)
            .getSuitableRoomByDay(day, diffDays);
    }

    getAnalysisData(
        day: number,
        compareRoom: RatesDocumentItemModel | null,
        hotelId: number,
        documentIndex = 0,
    ) {
        if (!compareRoom) {
            return { price: -1, isBasic: false };
        }

        const { priceShown } = this.documentFiltersService;
        const { isBasic } = compareRoom;

        const price = this.ratesCommonService
            .switchPrice(compareRoom, priceShown);

        const losRestriction = this.ratesAnalysisService
            .getHotelLosRestriction(day as Day, hotelId, documentIndex);

        const room = this.ratesAnalysisService
            .getRoom(day as Day, hotelId, this.priceShown, documentIndex);

        return {
            isBasic,
            price,
            losRestriction,
            room,
        };
    }

    toggleGraph(hotel: TableData) {
        this.$emit('toggle-hotel', hotel);
    }
}
